import './styles/index.css'
import logo from './assets/images/logo-nakaverse.png';
import igIcon from './assets/icons/instagram.png';
import waIcon from './assets/icons/whatsapp.png';
import websiteIcon from './assets/icons/website.png';
import LinkCardComponent from './components/LinkCardComponent';
import shopeeIcon from './assets/icons/shopee.png';
import tokpedIcon from './assets/icons/tokopedia.png'
import twitterIcon from './assets/icons/twitter.png'

function App() {
  const data = {
    name: 'Nakaverse',
    avatar: './assets/images/about-logo-nakaverse.jpg',
    links: [
      {
        title: 'Shopee',
        href: 'https://shopee.co.id/nakaverseid',
        image: shopeeIcon
      },
      {
        title: 'Tokopedia',
        href: 'https://www.tokopedia.com/nakaverseid',
        image: tokpedIcon
      },
      {
        title: 'Website',
        href: 'https://nakaverse.id/',
        image: websiteIcon
      },
    ],
    socials: [
      {
        title: 'Twitter',
        href: 'https://twitter.com/nakaverseid',
        image: twitterIcon
      },
      {
        title: 'Instagram',
        href: 'https://instagram.com/nakaverseid/',
        image: igIcon
      },
      {
        title: 'WhatsApp',
        href: 'https://api.whatsapp.com/send?phone=6285186877732&text=*Assalamuallaikum*%20Admin%20_Nakaverse',
        image: waIcon
      },
    ]
  };
  return (
    <div className="flex items-center flex-col mx-auto w-full justify-center mt-16 px-8">
      <img
        className='mr-5'
        alt={data.name}
        src={logo}
        width={200}
        height={200}
      />
      <h1 className="font-bold mt-4 mb-8 text-xl text-white">{data.name}</h1>
      {data.links.map((link) => (
        <LinkCardComponent key={link.href} {...link} />
      ))}
      <div className="flex items-center gap-4 mt-8 text-white">
        {data.socials.map((social) => (
          <a
            aria-label={`${social.title} link`}
            key={social.href}
            href={social.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt={social.title}
              src={social.image}
              width={40}
              height={40}
            />
          </a>
        ))}
      </div>
    </div>
  );
}

export default App;
